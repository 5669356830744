// @flow
import {all, call, fork, put, take, takeEvery} from 'redux-saga/effects';
import { fetchJSON } from '../../../helpers/api';

import i18n from 'i18next';
import {
    LIVE_QUESTION_CHANGE,
    LIVE_QUESTION_GET,
    LIVE_QUESTION_GET_FAILED,
    LIVE_QUESTION_GET_SUCCESS, LIVE_QUESTION_UPDATE, LIVE_QUESTION_UPDATE_FAILED,
    LIVE_QUESTION_UPDATE_SUCCESS
} from "../constants/questionConstants";

import {getSocket} from "../../../socket";
import {eventChannel} from "redux-saga";
import {onQuestionChange} from "../actions/questionActions";

const socket = getSocket();

/**
 * get liveQuestion
 * @param {*} payload none
 */
function* getLiveQuestion({ payload: { id } }) {
    const options = {
        body: JSON.stringify({ id }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const response = yield call(fetchJSON, process.env.REACT_APP_LIVE + '/question', options);
        if (response.id){
            yield put({type: LIVE_QUESTION_GET_SUCCESS, liveQuestion: response });
        } else {
            yield put({type: LIVE_QUESTION_GET_FAILED, payload: i18n.t(response.errors)});
        }

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = i18n.t('auth.errors.500');
                break;
            case 401:
                message = i18n.t('auth.errors.401');
                break;
            default:
                message = error;
        }
        yield put({type: LIVE_QUESTION_GET_FAILED, error: message});
    }
}

/**
 * update liveQuestion
 * @param {*} payload values
 */
function* updateLiveQuestion({ payload: { token, question, values } }) {

    const options = {
        body: JSON.stringify({ token, question, values }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const response = yield call(fetchJSON, process.env.REACT_APP_LIVE + '/question/update', options);
        if (response.errors) {
            yield put({type: LIVE_QUESTION_UPDATE_FAILED, payload: i18n.t(response.errors)});
        } else {
            yield put({type: LIVE_QUESTION_UPDATE_SUCCESS, id: response.success });
            socket.emit('update-escapeRoom', token);
            socket.emit('update-escape', response.success);
            socket.emit('update-escapeAdmin', response.success);
            socket.emit('question-change', token);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = i18n.t('auth.errors.500');
                break;
            case 401:
                message = i18n.t('auth.errors.401');
                break;
            default:
                message = error;
        }
        yield put({type: LIVE_QUESTION_UPDATE_FAILED, payload: i18n.t(message)});
    }
}

function connect() {
    const room = window.location.href.substr(window.location.href.lastIndexOf('/') + 1);
    const socket = getSocket();
    return new Promise(resolve => {
        console.log('LISTENING TO Live Question');
        socket.on('connect', () => {
            socket.emit('question-init', room);
            resolve(socket);
        });
    });
}

function* liveQuestionGet(socket) {
    const channel = yield call(subscribeQuestion, socket);
    while (true) {
        let action = yield take(channel);
        yield put(action);
    }
}

function* liveQuestionChange(socket) {

    while (true) {
        const {token} = yield take(LIVE_QUESTION_CHANGE);
        socket.emit('question-changed', token);
    }
}

export function* subscribeQuestion(socket) {
    yield 1;
    return new eventChannel(emit => {
        const update = id => {
            return emit(onQuestionChange(id));
        }
        socket.on('question-get', update);
        return () => {}
    })
}

export function* watchLiveQuestionUpdate(): any {
    yield takeEvery(LIVE_QUESTION_UPDATE, updateLiveQuestion);
}

export function* watchLiveQuestionGet(): any {
    yield takeEvery(LIVE_QUESTION_GET, getLiveQuestion);
}

/*
function* nextLiveQuestion({ payload: { token } }) {
    socket.emit('update-escapeRoom', token);
}



export function* watchLiveQuestionNext(): any {
    yield takeEvery(LIVE_NEXT_QUESTION, nextLiveQuestion);
} */

function* liveQuestionSaga(): any {
    yield all([
        fork(watchLiveQuestionUpdate),
        fork(watchLiveQuestionGet)
        /*
        fork(watchLiveQuestionNext) */
    ]);
    yield take(LIVE_QUESTION_CHANGE)
    const socket = yield call(connect)
    yield fork(liveQuestionChange, socket)
    yield fork(liveQuestionGet, socket)
}

export default liveQuestionSaga;
