// @flow
import {
    ENTITIES_REQUEST,
    ENTITY_ADD,
    ENTITY_GET,
    ENTITY_UPDATE,
    ENTITY_DELETE,
    ENTITY_CLONE,
    MODAL_ENTITIES_REQUEST,
    MODAL_ENTITY_ADD,
    MODAL_ENTITY_GET,
    MODAL_ENTITY_UPDATE,
    MODAL_ENTITY_DELETE,
    MODAL_ENTITY_RESET,
    MODAL_ENTITY_CLONE,
} from './constants';

type EntitiesAction = { type: string, payload: {} | string };

export const entitiesRequestSaga = (path, params): EntitiesAction => ({
    type: ENTITIES_REQUEST,
    payload: {path, params},
});

export const entityAddSaga = (path, values, history, forwarding): EntitiesAction => ({
    type: ENTITY_ADD,
    payload: {path, values, history, forwarding},
});

export const entityGetSaga = (path, id, callback): EntitiesAction => ({
    type: ENTITY_GET,
    payload: {path, id, callback},
});

export const entityUpdateSaga = (path, values, id, history, forwarding): EntitiesAction => ({
    type: ENTITY_UPDATE,
    payload: {path, values, id, history, forwarding},
});

export const entityDeleteSaga = (path, id, onClose, forwarding): EntitiesAction => ({
    type: ENTITY_DELETE,
    payload: {path, id, onClose, forwarding},
});

export const entityCloneSaga = (path, id, onClose, forwarding): EntitiesAction => ({
    type: ENTITY_CLONE,
    payload: {path, id, onClose, forwarding},
});

/* MODAL */

type ModalEntitiesAction = { type: string, payload: {} | string };

export const modalEntitiesRequestSaga = (path, parent): ModalEntitiesAction => ({
    type: MODAL_ENTITIES_REQUEST,
    payload: {path, parent},
});

export const modalEntityAddSaga = (path, values, forwarding, parent): ModalEntitiesAction => ({
    type: MODAL_ENTITY_ADD,
    payload: {path, values, forwarding, parent},
});

export const modalEntityGetSaga = (path, id): ModalEntitiesAction => ({
    type: MODAL_ENTITY_GET,
    payload: {path, id},
});

export const modalEntityUpdateSaga = (path, values, id, forwarding, parent): ModalEntitiesAction => ({
    type: MODAL_ENTITY_UPDATE,
    payload: {path, values, id, forwarding, parent},
});

export const modalEntityDeleteSaga = (path, id, onClose, forwarding, parent): ModalEntitiesAction => ({
    type: MODAL_ENTITY_DELETE,
    payload: {path, id, onClose, forwarding, parent},
});

export const modalEntityCloneSaga = (path, id, onClose, forwarding, parent): ModalEntitiesAction => ({
    type: MODAL_ENTITY_CLONE,
    payload: {path, id, onClose, forwarding, parent},
});

export const modalEntityResetSaga = (): ModalEntitiesAction => ({
    type: MODAL_ENTITY_RESET
});