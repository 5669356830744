// @flow
import {
    LIVE_QUESTION_GET,
    LIVE_QUESTION_UPDATE,
    LIVE_NEXT_QUESTION, LIVE_QUESTION_CHANGE
} from "../constants/questionConstants";

export const liveGetQuestion = (id) => {
    return {
        type : LIVE_QUESTION_GET,
        payload: {id},
    }
}

export const liveUpdateQuestion = (token, question, values) => {
    return {
        type : LIVE_QUESTION_UPDATE,
        payload: {token, question, values},
    }
}

export const liveQuestionChange = (id) => {
    return {
        type : LIVE_QUESTION_CHANGE,
        payload: {id},
    }
}

export const onQuestionChange = (id) => {
    return {
        type : LIVE_QUESTION_GET,
        payload: {id},
    }
}

export const liveNextQuestion = (token) => {
    return {
        type : LIVE_NEXT_QUESTION,
        payload: {token},
    }
}