// @flow
import { all, call, fork, put, takeEvery} from 'redux-saga/effects';
import { fetchJSON } from '../../helpers/api';
import {
    ESCAPE_ROOM_GET, ESCAPE_ROOM_GET_SUCCESS, ESCAPE_ROOM_GET_FAILED,
    ESCAPE_ROOM_REFRESH, ESCAPE_ROOM_REFRESH_SUCCESS, ESCAPE_ROOM_REFRESH_FAILED,
    ESCAPE_ROOM_UPDATE, ESCAPE_ROOM_UPDATE_SUCCESS, ESCAPE_ROOM_UPDATE_FAILED,
    ESCAPE_ROOM_LOGIN, ESCAPE_ROOM_LOGIN_SUCCESS, ESCAPE_ROOM_LOGIN_FAILED,
    ESCAPE_ROOM_LOGOUT, ESCAPE_UPDATE_FAILED, ESCAPE_UPDATE_SUCCESS, ESCAPE_UPDATE
} from './constants';

import i18n from 'i18next';
import {Cookies} from "react-cookie";
import {changeBodyBackgroundColor, changeBodyBackgroundImage} from "../../helpers/authUtils";
import {getSocket} from "../../socket";

const socket = getSocket();

const initEscape = escapeRoom => {

    const bgColor = escapeRoom.escape.customBackgroundColor;
    const bgImage = process.env.REACT_APP_MEDIA + '/escape/' + escapeRoom.escape.id + '/' + escapeRoom.escape.customBackground;

    changeBodyBackgroundImage(bgImage);
    changeBodyBackgroundColor(bgColor);

};

const setLiveSession = live => {

    let cookies = new Cookies();
    if (!live) {
        cookies.remove('live', {path: '/'});
        return;
    }

    cookies.set('live', JSON.stringify(live), { path: '/' });

    return live;
}

const setDashboardSession = myDashboard => {

    let cookies = new Cookies();
    if (!myDashboard) {
        cookies.remove('myDashboard', {path: '/'});
        return;
    } else {
        console.log(myDashboard);
    }

    cookies.set('myDashboard', JSON.stringify(myDashboard), { path: '/' });

    return myDashboard;
}


/**
 * get escapeRoom
 * @param {*} payload none
 */
function* getEscapeRoom({ payload: { id } }) {

    const options = {
        body: JSON.stringify({ id }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const response = yield call(fetchJSON, process.env.REACT_APP_LIVE + '/escape', options);
        if (response.id){
            initEscape(response);
        }
        yield put({type: ESCAPE_ROOM_GET_SUCCESS, escapeRoom: response });

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = i18n.t('auth.errors.500');
                break;
            case 401:
                message = i18n.t('auth.errors.401');
                break;
            default:
                message = error;
        }
        yield put({type: ESCAPE_ROOM_GET_FAILED, error: message});
    }
}

/**
 * refresh escapeRoom
 * @param {*} payload none
 */
function* refreshEscapeRoom({ payload: { id, refreshEscapeRoom } }) {

    const options = {
        body: JSON.stringify({ id }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const response = yield call(fetchJSON, process.env.REACT_APP_LIVE + '/escape/refresh', options);
        if (refreshEscapeRoom){
            refreshEscapeRoom(response);
        }
        yield put({type: ESCAPE_ROOM_REFRESH_SUCCESS, escapeRoomData: response });

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = i18n.t('auth.errors.500');
                break;
            case 401:
                message = i18n.t('auth.errors.401');
                break;
            default:
                message = error;
        }
        yield put({type: ESCAPE_ROOM_REFRESH_FAILED, error: message});
    }
}

/**
 * login escapeRoom
 * @param {*} payload values
 */
function* loginEscapeRoom({ payload: { values } }) {
    const options = {
        body: JSON.stringify({ values }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const response = yield call(fetchJSON, process.env.REACT_APP_LIVE + '/login', options);
        if (response.id) {
            setLiveSession(response);
            yield put({type: ESCAPE_ROOM_LOGIN_SUCCESS, login: response});
            socket.emit('update-escapeAdmin', response.escapeRoom.escape.id);
        } else {
            yield put({type: ESCAPE_ROOM_LOGIN_FAILED, payload: i18n.t(response.errors)});
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = i18n.t('auth.errors.500');
                break;
            case 401:
                message = i18n.t('auth.errors.401');
                break;
            default:
                message = error;
        }
        yield put({type: ESCAPE_ROOM_LOGIN_FAILED, payload: i18n.t(message)});
    }
}

/**
 * Logout the participant
 * @param {*} param0
 */
function* logoutEscapeRoom({ payload: { id, escape, token, history } }) {

    const options = {
        body: JSON.stringify({ id, escape }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const response = yield call(fetchJSON, process.env.REACT_APP_LIVE + '/logout', options);
        if (response.success) {
            setLiveSession(null);
            setDashboardSession(null);
            yield call(() => {
                history.push('/escapeRoomHome/' + escape + '/' + token);
            });
            socket.emit('update-escapeAdmin', escape);
        }
    } catch (error) {}
}

/**
 * update escapeRoom
 * @param {*} payload values
 */
function* updateEscapeRoom({ payload: { values, id, history, forwarding } }) {
    const options = {
        body: JSON.stringify({ values, id }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const response = yield call(fetchJSON, process.env.REACT_APP_BACKEND + '/escape/room/update', options);
        if (response.id) {
            yield put({type: ESCAPE_ROOM_UPDATE_SUCCESS, entity: response });
            yield call(() => {
                history.push(forwarding);
            });
        } else {
            yield put({type: ESCAPE_ROOM_UPDATE_FAILED, payload: i18n.t(response.errors)});
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = i18n.t('auth.errors.500');
                break;
            case 401:
                message = i18n.t('auth.errors.401');
                break;
            default:
                message = error;
        }
        yield put({type: ESCAPE_ROOM_UPDATE_FAILED, payload: i18n.t(message)});
    }
}

/**
 * update escape
 * @param {*} payload values
 */
function* updateEscape({ payload: { id, status, pausedSeconds } }) {
    const options = {
        body: JSON.stringify({ id, status, pausedSeconds }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const response = yield call(fetchJSON, process.env.REACT_APP_BACKEND + '/escape/status', options);
        if (response.id) {
            yield put({type: ESCAPE_UPDATE_SUCCESS, escape: response});
            if (status === 666) {
                socket.emit('eject-escape', response.id);
            } else {
                socket.emit('update-escape', response.id);
            }
            socket.emit('messages-read', response.id);
            response.escapeRooms.forEach(function(room){
                socket.emit('update-escapeRoom', room.token);
                socket.emit('update-escapeAdmin', response.id);
            });
        } else {
            yield put({type: ESCAPE_UPDATE_FAILED, payload: i18n.t(response.errors)});
        }
    } catch (error) {
        yield put({type: ESCAPE_UPDATE_FAILED, payload: i18n.t(error)});
    }
}

export function* watchEscapeRoomGet(): any {
    yield takeEvery(ESCAPE_ROOM_GET, getEscapeRoom);
}

export function* watchEscapeRoomRefresh(): any {
    yield takeEvery(ESCAPE_ROOM_REFRESH, refreshEscapeRoom);
}

export function* watchEscapeRoomLogin(): any {
    yield takeEvery(ESCAPE_ROOM_LOGIN, loginEscapeRoom);
}

export function* watchEscapeRoomLogout(): any {
    yield takeEvery(ESCAPE_ROOM_LOGOUT, logoutEscapeRoom);
}

export function* watchEscapeRoomUpdate(): any {
    yield takeEvery(ESCAPE_ROOM_UPDATE, updateEscapeRoom);
}

export function* watchEscapeUpdate(): any {
    yield takeEvery(ESCAPE_UPDATE, updateEscape);
}

function* escapeRoomSaga(): any {
    yield all([
        fork(watchEscapeRoomGet),
        fork(watchEscapeRoomRefresh),
        fork(watchEscapeRoomLogin),
        fork(watchEscapeRoomLogout),
        fork(watchEscapeRoomUpdate),
        fork(watchEscapeUpdate)
    ]);
}

export default escapeRoomSaga;
