import {ON_LIVE_ESCAPE_ROOM_UPDATED} from "../constants/escapeRoomConstants";

const initialState = {
    escapeRoom : [],
    questions: []
}

export default function LiveEscapeRoomReducer(state = initialState, action) {
    switch (action.type) {
        case ON_LIVE_ESCAPE_ROOM_UPDATED:
            return {
                ...state,
                escapeRoom : action.payload.escapeRoom,
                questions : action.payload.questions
            }
        default:
            return state
    }
}