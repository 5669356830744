export const ESCAPE_ROOM_GET = 'ESCAPE_ROOM_GET';
export const ESCAPE_ROOM_GET_SUCCESS = 'ESCAPE_ROOM_GET_SUCCESS';
export const ESCAPE_ROOM_GET_FAILED = 'ESCAPE_ROOM_GET_FAILED';
export const ESCAPE_ROOM_UPDATE = 'ESCAPE_ROOM_UPDATE';
export const ESCAPE_ROOM_UPDATE_SUCCESS = 'ESCAPE_ROOM_UPDATE_SUCCESS';
export const ESCAPE_ROOM_UPDATE_FAILED = 'ESCAPE_ROOM_UPDATE_FAILED';
export const ESCAPE_ROOM_REFRESH = 'ESCAPE_ROOM_REFRESH';
export const ESCAPE_ROOM_REFRESH_SUCCESS = 'ESCAPE_ROOM_REFRESH_SUCCESS';
export const ESCAPE_ROOM_REFRESH_FAILED = 'ESCAPE_ROOM_REFRESH_FAILED';
export const ESCAPE_ROOM_LOGIN = 'ESCAPE_ROOM_LOGIN';
export const ESCAPE_ROOM_LOGIN_SUCCESS = 'ESCAPE_ROOM_LOGIN_SUCCESS';
export const ESCAPE_ROOM_LOGIN_FAILED = 'ESCAPE_ROOM_LOGIN_FAILED';
export const ESCAPE_ROOM_LOGOUT = 'ESCAPE_ROOM_LOGOUT';

export const ESCAPE_UPDATE = 'ESCAPE_UPDATE';
export const ESCAPE_UPDATE_SUCCESS = 'ESCAPE_UPDATE_SUCCESS';
export const ESCAPE_UPDATE_FAILED = 'ESCAPE_UPDATE_FAILED';

