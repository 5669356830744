/* API */
export const ENTITIES_REQUEST = 'ENTITIES_REQUEST';
export const ENTITIES_SUCCESS = 'ENTITIES_SUCCESS';
export const ENTITIES_FAILED = 'ENTITIES_FAILED';
export const ENTITY_ADD = 'ENTITY_ADD';
export const ENTITY_ADD_SUCCESS = 'ENTITY_ADD_SUCCESS';
export const ENTITY_ADD_FAILED = 'ENTITY_ADD_FAILED';
export const ENTITY_GET = 'ENTITY_GET';
export const ENTITY_GET_SUCCESS = 'ENTITY_GET_SUCCESS';
export const ENTITY_GET_FAILED = 'ENTITY_GET_FAILED';
export const ENTITY_UPDATE = 'ENTITY_EDIT';
export const ENTITY_UPDATE_SUCCESS = 'ENTITY_EDIT_SUCCESS';
export const ENTITY_UPDATE_FAILED = 'ENTITY_EDIT_FAILED';
export const ENTITY_DELETE = 'ENTITY_DELETE';
export const ENTITY_DELETE_SUCCESS = 'ENTITY_DELETE_SUCCESS';
export const ENTITY_DELETE_FAILED = 'ENTITY_DELETE_FAILED';
export const ENTITY_CLONE = 'ENTITY_CLONE';
export const ENTITY_CLONE_SUCCESS = 'ENTITY_CLONE_SUCCESS';
export const ENTITY_CLONE_FAILED = 'ENTITY_CLONE_FAILED';

/* MODAL API */
export const MODAL_ENTITIES_REQUEST = 'MODAL_ENTITIES_REQUEST';
export const MODAL_ENTITIES_SUCCESS = 'MODAL_ENTITIES_SUCCESS';
export const MODAL_ENTITIES_FAILED = 'MODAL_ENTITIES_FAILED';
export const MODAL_ENTITY_ADD = 'MODAL_ENTITY_ADD';
export const MODAL_ENTITY_ADD_SUCCESS = 'MODAL_ENTITY_ADD_SUCCESS';
export const MODAL_ENTITY_ADD_FAILED = 'MODAL_ENTITY_ADD_FAILED';
export const MODAL_ENTITY_GET = 'MODAL_ENTITY_GET';
export const MODAL_ENTITY_GET_SUCCESS = 'MODAL_ENTITY_GET_SUCCESS';
export const MODAL_ENTITY_GET_FAILED = 'MODAL_ENTITY_GET_FAILED';
export const MODAL_ENTITY_UPDATE = 'MODAL_ENTITY_EDIT';
export const MODAL_ENTITY_UPDATE_SUCCESS = 'MODAL_ENTITY_EDIT_SUCCESS';
export const MODAL_ENTITY_UPDATE_FAILED = 'MODAL_ENTITY_EDIT_FAILED';
export const MODAL_ENTITY_DELETE = 'MODAL_ENTITY_DELETE';
export const MODAL_ENTITY_DELETE_SUCCESS = 'MODAL_ENTITY_DELETE_SUCCESS';
export const MODAL_ENTITY_DELETE_FAILED = 'MODAL_ENTITY_DELETE_FAILED';
export const MODAL_ENTITY_RESET = 'MODAL_ENTITY_RESET';
export const MODAL_ENTITY_RESET_SUCCESS = 'MODAL_ENTITY_RESET_SUCCESS';
export const MODAL_ENTITY_CLONE = 'MODAL_ENTITY_CLONE';
export const MODAL_ENTITY_CLONE_SUCCESS = 'MODAL_ENTITY_CLONE_SUCCESS';
export const MODAL_ENTITY_CLONE_FAILED = 'MODAL_ENTITY_CLONE_FAILED';

