// @flow
import { all } from 'redux-saga/effects';
import authSaga from './auth/saga';
import layoutSaga from './layout/saga';
import appMenuSaga from './appMenu/saga';
import commonSaga from "./common/saga";
import EntitySaga from "./api/saga";
import EscapeRoomSaga from "./escapeRoom/saga";
import {LiveEscapeSaga} from "./socket/sagas/escapeSaga"
import {LiveEscapeRoomSaga} from "./socket/sagas/escapeRoomSaga"
import LiveQuestionSaga from "./socket/sagas/questionSaga"
import {LiveEscapeAdminSaga} from "./socket/sagas/escapeAdminSaga"
import {MessagesSaga} from "./socket/sagas/messagesSaga"

export default function* rootSaga(getState: any): any {
    yield all([
        authSaga(),
        layoutSaga(),
        appMenuSaga(),
        commonSaga(),
        EntitySaga(),
        EscapeRoomSaga(),
        LiveEscapeSaga(),
        LiveEscapeRoomSaga(),
        LiveQuestionSaga(),
        LiveEscapeAdminSaga(),
        MessagesSaga()
    ]);
}