import {
    GET_MESSAGES,
    SENT_MESSAGES,
    ON_SENT_MESSAGES
} from "../constants/messagesConstants";

export const getMessages = (id) => {
    return {
        type : GET_MESSAGES,
        payload: {id},
    }
}

export const sentMessages = (escape, room, messages) => {
    return {
        type : SENT_MESSAGES,
        payload: {escape, room, messages},
    }
}

export const onSentMessages = payload => {
    return {
        type : ON_SENT_MESSAGES,
        payload
    }
}

