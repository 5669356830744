import {take,put,call,fork} from 'redux-saga/effects'
import {eventChannel} from 'redux-saga'
import {
    LIVE_ESCAPE_ADMIN_GET, LIVE_ESCAPE_ADMIN_UPDATE
} from '../constants/escapeAdminConstants';

import {getSocket} from "../../../socket";
import {onEscapeAdminUpdated} from "../actions/escapeAdminAction";

function connect() {
    const room = window.location.href.substr(window.location.href.lastIndexOf('/') + 1);
    const socket = getSocket();
    return new Promise(resolve => {
        socket.on('connect', () => {
            console.log('LISTENING TO escapeAdmin');
            socket.emit('escapeAdmin', room);
            resolve(socket);
        });
    });
}

function* liveGetEscapeAdmin(socket) {
    const channel = yield call(subscribeEscapeAdmin, socket);
    while (true) {
        let action = yield take(channel);
        yield put(action);
    }
}

function* liveUpdateEscapeAdmin(socket) {

    while (true) {
        const {response} = yield take(LIVE_ESCAPE_ADMIN_UPDATE);
        socket.emit('update-escapeAdmin', response.id);
    }
}

export function* subscribeEscapeAdmin(socket) {
    yield 1;
    return new eventChannel(emit => {
        const update = EscapeAdmin => {
            return  emit(onEscapeAdminUpdated(EscapeAdmin));
        }
        socket.on('get-escapeAdmin', update);
        return () => {}
    })
}

export function* LiveEscapeAdminSaga() {
    yield take(LIVE_ESCAPE_ADMIN_GET)
    const socket = yield call(connect)
    yield fork(liveGetEscapeAdmin, socket)
    yield fork(liveUpdateEscapeAdmin, socket)
}