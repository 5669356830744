// @flow
import {
    ENTITIES_REQUEST, ENTITIES_SUCCESS, ENTITIES_FAILED,
    ENTITY_ADD, ENTITY_ADD_SUCCESS, ENTITY_ADD_FAILED,
    ENTITY_GET, ENTITY_GET_SUCCESS, ENTITY_GET_FAILED,
    ENTITY_UPDATE, ENTITY_UPDATE_SUCCESS, ENTITY_UPDATE_FAILED,
    ENTITY_DELETE, ENTITY_DELETE_SUCCESS, ENTITY_DELETE_FAILED,
    ENTITY_CLONE, ENTITY_CLONE_SUCCESS, ENTITY_CLONE_FAILED,
    MODAL_ENTITIES_REQUEST, MODAL_ENTITIES_SUCCESS, MODAL_ENTITIES_FAILED,
    MODAL_ENTITY_ADD, MODAL_ENTITY_ADD_SUCCESS, MODAL_ENTITY_ADD_FAILED,
    MODAL_ENTITY_GET, MODAL_ENTITY_GET_SUCCESS, MODAL_ENTITY_GET_FAILED,
    MODAL_ENTITY_UPDATE, MODAL_ENTITY_UPDATE_SUCCESS, MODAL_ENTITY_UPDATE_FAILED,
    MODAL_ENTITY_DELETE, MODAL_ENTITY_DELETE_SUCCESS, MODAL_ENTITY_DELETE_FAILED,
    MODAL_ENTITY_CLONE, MODAL_ENTITY_CLONE_SUCCESS, MODAL_ENTITY_CLONE_FAILED,
    MODAL_ENTITY_RESET, MODAL_ENTITY_RESET_SUCCESS
} from './constants';

const initialState = {
    entityData: {
        questions: [],
        question: {
            questionAnswers: [],
            questionAnswersCorrect: []
        },
    },
    entityLoading: false,
    entityError: '',
    modalEntitiesData: {
    },
    modalEntitiesLoading: false,
    modalEntitiesError: '',
    modalEntityData: {},
    modalEntityLoading: false,
    modalEntityError: ''
};

export default function EntitiesReducer(state= initialState, action) {
    switch (action.type) {
        case ENTITIES_REQUEST:
            return { ...state, entityLoading: true, entityError: null };
        case ENTITIES_SUCCESS:
            return { ...state, entityData: action.data, entityLoading: false };
        case ENTITIES_FAILED:
            return { ...state, entityError: action.error, entityLoading: false };
        case ENTITY_ADD:
            return { ...state, entityLoading: true, entityError: null };
        case ENTITY_ADD_SUCCESS:
            return { ...state, entityData: action.entity, entityLoading: false };
        case ENTITY_ADD_FAILED:
            return { ...state, entityError: action.error, entityLoading: false };
        case ENTITY_GET:
            return { ...state, entityData: action.entity, entityLoading: true, entityError: null };
        case ENTITY_GET_SUCCESS:
            return { ...state, entityData: action.entity, entityLoading: false };
        case ENTITY_GET_FAILED:
            return { ...state, entityError: action.error, entityLoading: false };
        case ENTITY_UPDATE:
            return { ...state, entityLoading: true, entityError: null };
        case ENTITY_UPDATE_SUCCESS:
            return { ...state, entityData: action.entity, entityLoading: false };
        case ENTITY_UPDATE_FAILED:
            return { ...state, entityError: action.payload, entityLoading: false };
        case ENTITY_DELETE:
            return { ...state, entityStatus: action.entity, entityLoading: true, entityError: null };
        case ENTITY_DELETE_SUCCESS:
            return { ...state, entityStatus: action.entity, entityLoading: false };
        case ENTITY_DELETE_FAILED:
            return { ...state, entityError: action.payload, entityLoading: false };
        case ENTITY_CLONE:
            return { ...state, entityStatus: action.entity, entityLoading: true, entityError: null };
        case ENTITY_CLONE_SUCCESS:
            return { ...state, entityStatus: action.entity, entityLoading: false };
        case ENTITY_CLONE_FAILED:
            return { ...state, entityError: action.payload, entityLoading: false };

            /* MODAL CRUD */

        case MODAL_ENTITIES_REQUEST:
            return { ...state, modalEntitiesLoading: true, modalEntitiesError: null };
        case MODAL_ENTITIES_SUCCESS:
            return { ...state, modalEntitiesData: action.data, modalEntitiesLoading: false };
        case MODAL_ENTITIES_FAILED:
            return { ...state, modalEntitiesError: action.error, modalEntitiesLoading: false };

        case MODAL_ENTITY_ADD:
            return { ...state, modalEntityLoading: true, modalEntityError: null };
        case MODAL_ENTITY_ADD_SUCCESS:
            return { ...state, modalEntityData: action.modalEntity, modalEntityLoading: false };
        case MODAL_ENTITY_ADD_FAILED:
            return { ...state, modalEntityError: action.error, modalEntityLoading: false };

        case MODAL_ENTITY_GET:
            return { ...state, modalEntityData: action.modalEntity, modalEntityLoading: true, modalEntityError: null };
        case MODAL_ENTITY_GET_SUCCESS:
            return { ...state, modalEntityData: action.modalEntity, modalEntityLoading: false };
        case MODAL_ENTITY_GET_FAILED:
            return { ...state, modalEntityError: action.error, modalEntityLoading: false };

        case MODAL_ENTITY_UPDATE:
            return { ...state, modalEntityLoading: true, modalEntityError: null };
        case MODAL_ENTITY_UPDATE_SUCCESS:
            return { ...state, modalEntityData: action.modalEntity, modalEntityLoading: false };
        case MODAL_ENTITY_UPDATE_FAILED:
            return { ...state, modalEntityError: action.payload, modalEntityLoading: false };

        case MODAL_ENTITY_DELETE:
            return { ...state, modalEntityStatus: action.modalEntity, modalEntityLoading: true, modalEntityError: null };
        case MODAL_ENTITY_DELETE_SUCCESS:
            return { ...state, modalEntityStatus: action.modalEntity, modalEntityLoading: false };
        case MODAL_ENTITY_DELETE_FAILED:
            return { ...state, modalEntityError: action.payload, modalEntityLoading: false };

        case MODAL_ENTITY_CLONE:
            return { ...state, modalEntityStatus: action.modalEntity, modalEntityLoading: true, modalEntityError: null };
        case MODAL_ENTITY_CLONE_SUCCESS:
            return { ...state, modalEntityStatus: action.modalEntity, modalEntityLoading: false };
        case MODAL_ENTITY_CLONE_FAILED:
            return { ...state, modalEntityError: action.payload, modalEntityLoading: false };

        case MODAL_ENTITY_RESET:
            return { ...state, modalEntityData: {}, modalEntityLoading: false, modalEntityError: null };
        case MODAL_ENTITY_RESET_SUCCESS:
            return { ...state, modalEntityData: action.modalEntity, modalEntityLoading: false };

        default:
            return { ...state };
    }
}