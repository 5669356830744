// @flow
import {
    COMMON_REQUEST,
    COMMON_SUCCESS,
    COMMON_FAILED,
    QUESTION_TYPE_REQUEST,
    QUESTION_TYPE_SUCCESS,
    QUESTION_TYPE_FAILED,
    GAME_REQUEST,
    GAME_SUCCESS,
    GAME_FAILED,
} from './constants';

const initialState = {
    loading: false,
    error: '',
    questionTypes: [],
    games: [],
    feedback: false,
};

export default function CommonReducer(state= initialState, action) {
    switch (action.type) {
        case COMMON_REQUEST:
            return { ...state, loading: true, error: null };
        case COMMON_SUCCESS:
            return { ...state, feedback: true, loading: false };
        case COMMON_FAILED:
            return { ...state, error: action.error, loading: false };
        case QUESTION_TYPE_REQUEST:
            return { ...state, loading: true, error: null };
        case QUESTION_TYPE_SUCCESS:
            return { ...state, questionTypes: action.questionTypes, loading: false };
        case QUESTION_TYPE_FAILED:
            return { ...state, error: action.error, loading: false };
        case GAME_REQUEST:
            return { ...state, loading: true, error: null };
        case GAME_SUCCESS:
            return { ...state, games: action.games, loading: false };
        case GAME_FAILED:
            return { ...state, error: action.error, loading: false };
        default:
            return { ...state };
    }
}