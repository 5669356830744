import {ON_SENT_MESSAGES} from "../constants/messagesConstants";

const initialState = {
    escape : '',
    room : '',
    messages : []
}

export default function messagesReducer(state = initialState, action) {
    switch (action.type) {
        case ON_SENT_MESSAGES:
            return {
                ...state,
                escape : action.payload.escape,
                room : action.payload.room,
                messages : action.payload.messages
            }
        default:
            return state
    }
}