// @flow
import { combineReducers } from 'redux';
import Layout from './layout/reducers';
import Auth from './auth/reducers';
import AppMenu from './appMenu/reducers';
import Common from "./common/reducers";
import Entities from "./api/reducers";
import EscapeRoom from "./escapeRoom/reducers";
import LiveEscape from "./socket/reducers/escapeReducer";
import LiveEscapeRoom from "./socket/reducers/escapeRoomReducer";
import LiveQuestion from "./socket/reducers/questionReducer";
import LiveEscapeAdmin from "./socket/reducers/escapeAdminReducer";
import Messages from "./socket/reducers/messagesReducer";

export default combineReducers({
    Auth,
    AppMenu,
    Layout,
    Common,
    Entities,
    EscapeRoom,
    LiveEscape,
    LiveEscapeRoom,
    LiveQuestion,
    LiveEscapeAdmin,
    Messages
});
