import io from "socket.io-client";

export function getSocket() {
    return io(process.env.REACT_APP_SOCKET, {
        transports: ['websocket'],
        secure: true,
        path: '/socket.io'
    });
}

export function sendMessage(escape, escapeRoom, contents, sender, isPanic) {
    const socket = getSocket();
    const data = {escape, escapeRoom, contents, sender, isPanic}
    socket.emit('messages-send', data);
}

export function readMessages(escape) {
    const socket = getSocket();
    socket.emit('messages-read', {escape});
}

export function removeMessage(escape,message) {
    const socket = getSocket();
    socket.emit('messages-remove', {escape, message});
}