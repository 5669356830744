// @flow
import {
    COMMON_REQUEST, QUESTION_TYPE_REQUEST, GAME_REQUEST
} from './constants';

type CommonAction = { type: string, payload: {} | string };

export const commonRequest = (path, params): CommonAction => ({
    type: COMMON_REQUEST,
    payload: {path, params},
});

export const questionTypeRequest = (): CommonAction => ({
    type: QUESTION_TYPE_REQUEST,
    payload: {},
});

export const gameRequest = (): CommonAction => ({
    type: GAME_REQUEST,
    payload: {},
});