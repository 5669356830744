import {take, put, call, fork} from 'redux-saga/effects'
import {eventChannel} from 'redux-saga'
import {
    GET_MESSAGES, SENT_MESSAGES
} from "../constants/messagesConstants";

import {getSocket} from "../../../socket";
import {onSentMessages} from "../actions/messagesAction";

function connect() {
    const id = window.location.href.substr(window.location.href.lastIndexOf('/') + 1);
    const socket = getSocket();
    return new Promise(resolve => {
        socket.on('connect', () => {
            console.log('LISTENING TO messages');
            socket.emit('messages-init', id);
            resolve(socket);
        });
    });
}

function* getMessages(socket) {
    const channel = yield call(subscribeMessages, socket);
    while (true) {
        let action = yield take(channel);
        yield put(action);
    }
}

function* sentMessages(socket) {

    while (true) {
        const {response} = yield take(SENT_MESSAGES);
        socket.emit('messages-sent', response.id);
    }
}

export function* subscribeMessages(socket) {
    yield 1;
    return new eventChannel(emit => {
        const update = message => {
            return  emit(onSentMessages(message));
        }
        socket.on('messages-get', update);
        return () => {}
    })
}

export function* MessagesSaga() {
    yield take(GET_MESSAGES)
    const socket = yield call(connect)
    yield fork(getMessages, socket)
    yield fork(sentMessages, socket)
}