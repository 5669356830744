import {
    ON_LIVE_ESCAPE_UPDATED
} from '../constants/escapeConstants';

const initialState = {
    escape : []
}

export default function LiveEscapeReducer(state = initialState, action) {
    switch (action.type) {
        case ON_LIVE_ESCAPE_UPDATED:
            return {
                ...state,
                escape : action.payload.escape,
                escapeRooms : action.payload.escapeRooms,
                gameLevels : action.payload.gameLevels,
                eject : action.payload.eject
            }
        default:
            return state
    }
}