import React from 'react';
import { Redirect } from 'react-router-dom';
import { Route } from 'react-router-dom';

import { isUserAuthenticated, getLoggedInUser } from '../helpers/authUtils';

// lazy load all the views

// auth
const Login = React.lazy(() => import('../pages/auth/Login'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));
const ForgetPassword = React.lazy(() => import('../pages/auth/ForgetPassword'));
const Confirm = React.lazy(() => import('../pages/auth/Confirm'));
const Reset = React.lazy(() => import('../pages/auth/Reset'));

// dashboard
const Dashboard = React.lazy(() => import('../pages/app/dashboard/dashboard'));

// question
const QuestionList = React.lazy(() => import('../pages/app/question/QuestionList'));
const QuestionSimple = React.lazy(() => import('../pages/app/question/QuestionSimple'));
const QuestionChoices = React.lazy(() => import('../pages/app/question/QuestionChoices'));
const QuestionOrder = React.lazy(() => import('../pages/app/question/QuestionOrder'));
const QuestionVisual = React.lazy(() => import('../pages/app/question/QuestionVisual'));
const QuestionClassify = React.lazy(() => import('../pages/app/question/QuestionClassify'));
const QuestionFill = React.lazy(() => import('../pages/app/question/QuestionFill'));
const QuestionRelate = React.lazy(() => import('../pages/app/question/QuestionRelate'));
const QuestionPuzzle = React.lazy(() => import('../pages/app/question/QuestionPuzzle'));

// game
const GameList = React.lazy(() => import('../pages/app/game/GameList'));
const GameManage = React.lazy(() => import('../pages/app/game/GameManage'));

// Escape
const EscapeList = React.lazy(() => import('../pages/app/escape/EscapeList'));
const EscapeManage = React.lazy(() => import('../pages/app/escape/EscapeManage'));
const EscapeAdmin = React.lazy(() => import('../pages/app/escape/EscapeAdmin'));

// Auxiliary / user
const UserList = React.lazy(() => import('../pages/app/auxiliary/user/UserList'));
const UserManage = React.lazy(() => import('../pages/app/auxiliary/user/UserManage'));
// Auxiliary / questionType
const QuestionTypeList = React.lazy(() => import('../pages/app/auxiliary/questionType/QuestionTypeList'));
const QuestionTypeManage = React.lazy(() => import('../pages/app/auxiliary/questionType/QuestionTypeManage'));

// public
const EscapeRoom = React.lazy(() => import('../pages/app/escapeRoom/EscapeRoom'));
const EscapeHome = React.lazy(() => import('../pages/app/escapeRoom/EscapeHome'));
const LogoutRoom = React.lazy(() => import('../pages/app/escapeRoom/LogoutRoom'));

// handle auth and authorization
const PrivateRoute = ({ component: Component, roles, ...rest }) => (
    <Route
        {...rest}
        render={props => {
            if (!isUserAuthenticated()) {
                // not logged in so redirect to login page with the return url
                return <Redirect to={{ pathname: '/account/login', state: { from: props.location } }} />;
            }

            const loggedInUser = getLoggedInUser();
            // check if route is restricted by role
            if (roles && roles.indexOf(loggedInUser.role) === -1) {
                // role not authorised so redirect to home page
                return <Redirect to={{ pathname: '/' }} />;
            }

            // authorised so return component
            return <Component {...props} />;
        }}
    />
);

// root routes
const rootRoute = {
    path: '/',
    exact: true,
    component: () => <Redirect to="/dashboard" />,
    route: PrivateRoute,
};

const dashboardRoutes = {
    path: '/dashboard',
    name: 'menu.dashboards.main',
    icon: 'uil-home-alt',
    header: 'menu.labels.navigation',
    component: Dashboard,
    route: PrivateRoute
};

const questionRoutes = {
    name: 'menu.question.main',
    icon: 'uil-question-circle',
    path: '/question/list',
    component: QuestionList,
    route: PrivateRoute,
    roles: ['Admin']
};

const gameRoutes = {
    name: 'menu.game.main',
    icon: 'uil-game-structure',
    route: PrivateRoute,
    roles: ['Admin'],
    children: [
        {
            path: '/game/list',
            name: 'menu.game.list',
            component: GameList,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/game/add',
            name: 'menu.game.add',
            component: GameManage,
            route: PrivateRoute,
            roles: ['Admin']
        }
    ]
};

const escapeRoutes = {
    name: 'menu.escape.main',
    icon: 'uil-play-circle',
    route: PrivateRoute,
    roles: ['User', 'Admin'],
    children: [
        {
            path: '/escape/list',
            name: 'menu.escape.list',
            exact: true,
            component: EscapeList,
            route: PrivateRoute,
            roles: ['User', 'Admin']
        },
        {
            path: '/escape/add',
            name: 'menu.escape.add',
            exact: true,
            component: EscapeManage,
            route: PrivateRoute,
            roles: ['Admin']
        }
    ]
};

// Auxiliary tables
const auxiliaryRoutes = {
    path: '/auxiliary',
    name: 'menu.auxiliary.main',
    icon: 'uil-wrench',
    header: 'menu.labels.settings',
    route: PrivateRoute,
    roles: ['Admin'],
    children: [
        {
            path: '/auxiliary/user/list',
            name: 'menu.auxiliary.user.main',
            component: UserList,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/auxiliary/questionType/list',
            name: 'menu.auxiliary.questionType.main',
            component: QuestionTypeList,
            route: PrivateRoute,
            roles: ['Admin']
        }
    ],
};

const authUnexposedRoutes = {
    path: '/unexposed',
    name: 'unexposed',
    children: [
        {
            path: '/question/simple/add',
            name: 'menu.question.types.simple',
            component: QuestionSimple,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/question/simple/edit/:id',
            name: 'menu.question.edit',
            component: QuestionSimple,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/question/choices/add',
            name: 'menu.question.types.choices',
            component: QuestionChoices,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/question/choices/edit/:id',
            name: 'menu.question.choices',
            component: QuestionChoices,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/question/order/add',
            name: 'menu.question.types.order',
            component: QuestionOrder,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/question/order/edit/:id',
            name: 'menu.question.order',
            component: QuestionOrder,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/question/visual/add',
            name: 'menu.question.types.visual',
            component: QuestionVisual,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/question/visual/edit/:id',
            name: 'menu.question.visual',
            component: QuestionVisual,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/question/classify/add',
            name: 'menu.question.types.classify',
            component: QuestionClassify,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/question/classify/edit/:id',
            name: 'menu.question.classify',
            component: QuestionClassify,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/question/fill/add',
            name: 'menu.question.types.fill',
            component: QuestionFill,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/question/fill/edit/:id',
            name: 'menu.question.fill',
            component: QuestionFill,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/question/relate/add',
            name: 'menu.question.types.relate',
            component: QuestionRelate,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/question/relate/edit/:id',
            name: 'menu.question.relate',
            component: QuestionRelate,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/question/puzzle/add',
            name: 'menu.question.types.puzzle',
            component: QuestionPuzzle,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/question/puzzle/edit/:id',
            name: 'menu.question.puzzle',
            component: QuestionPuzzle,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/game/edit/:id',
            name: 'menu.game.edit',
            component: GameManage,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/escape/edit/:id',
            name: 'menu.escape.edit',
            component: EscapeManage,
            route: PrivateRoute,
            roles: ['User', 'Admin']
        },
        {
            path: '/escape/admin/:id',
            name: 'menu.escape.admin',
            component: EscapeAdmin,
            route: PrivateRoute,
            roles: ['User', 'Admin']
        },
        {
            path: '/auxiliary/user/add',
            name: 'menu.user.add',
            component: UserManage,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/auxiliary/user/edit/:id',
            name: 'menu.user.edit',
            component: UserManage,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/auxiliary/questionType/add',
            name: 'menu.questionType.add',
            component: QuestionTypeManage,
            route: PrivateRoute,
            roles: ['Admin']
        },
        {
            path: '/auxiliary/questionType/edit/:id',
            name: 'menu.questionType.edit',
            component: QuestionTypeManage,
            route: PrivateRoute,
            roles: ['Admin']
        }
    ]
}

// auth
const authRoutes = {
    path: '/account',
    name: 'Auth',
    children: [
        {
            path: '/account/login',
            name: 'Login',
            component: Login,
            route: Route,
        },
        {
            path: '/account/logout',
            name: 'Logout',
            component: Logout,
            route: Route,
        },
        {
            path: '/account/confirm',
            name: 'Confirm',
            component: Confirm,
            route: Route,
        },
        {
            path: '/account/forget-password',
            name: 'Forget Password',
            component: ForgetPassword,
            route: Route,
        },
        {
            path: '/account/reset/:token',
            name: 'Reset',
            component: Reset,
            route: Route,
        }
    ],
};

const escapeRoomRoutes = {
    path: '/escapeRoom',
    name: 'EscapeRoom',
    children: [
        {
            name: 'Logout',
            path: '/escapeRoomLogout/:id/:token',
            exact: true,
            component: LogoutRoom,
            route: Route
        },
        {
            name: 'EscapeHome',
            path: '/escapeRoomHome/:id/:token',
            exact: true,
            component: EscapeHome,
            route: Route
        },
        {
            name: 'EscapeRoom',
            path: '/escapeRoom/:id/:token',
            exact: true,
            component: EscapeRoom,
            route: Route
        }
    ]
};

const appRoutes = [
    questionRoutes,
    gameRoutes,
    escapeRoutes,
    auxiliaryRoutes
];


// flatten the list of all nested routes
const flattenRoutes = routes => {
    let flatRoutes = [];

    routes = routes || [];
    routes.forEach(item => {
        flatRoutes.push(item);

        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};

// All routes
const allRoutes = [rootRoute, dashboardRoutes, ...appRoutes, authRoutes, escapeRoomRoutes, authUnexposedRoutes];
const authProtectedRoutes = [dashboardRoutes, ...appRoutes];
const allFlattenRoutes = flattenRoutes(allRoutes);

export { allRoutes, authProtectedRoutes, allFlattenRoutes };