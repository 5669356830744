import {
    LIVE_ESCAPE_ROOM_GET,
    LIVE_ESCAPE_ROOM_UPDATE,
    ON_LIVE_ESCAPE_ROOM_UPDATED
} from "../constants/escapeRoomConstants";

export const liveGetEscapeRoom = (id) => {
    return {
        type : LIVE_ESCAPE_ROOM_GET,
        payload: {id},
    }
}

export const liveUpdateEscapeRoom = (id, status) => {
    return {
        type : LIVE_ESCAPE_ROOM_UPDATE,
        payload: {id, status},
    }
}

export const onEscapeRoomUpdated = payload => {
    return {
        type : ON_LIVE_ESCAPE_ROOM_UPDATED,
        payload
    }
}

