// @flow
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../helpers/api';

import {
    COMMON_REQUEST,
    COMMON_SUCCESS,
    COMMON_FAILED,
    QUESTION_TYPE_REQUEST,
    QUESTION_TYPE_SUCCESS,
    QUESTION_TYPE_FAILED, GAME_REQUEST, GAME_FAILED, GAME_SUCCESS
} from './constants';

import i18n from 'i18next';

/**
 * get common
 * @param { * } payload data
 */
function* getCommon({ payload: { path, params } }) {

    const options = {
        body: JSON.stringify({ params }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    let message = '';
    try {
        const response = yield call(fetchJSON, process.env.REACT_APP_BACKEND + path, options);
        if (response.errors) {
            message = response.errors;
        } else {
            yield put({type: COMMON_SUCCESS, data: response });
        }
    } catch (error) {
        switch (error.status) {
            case 500:
                message = i18n.t('auth.errors.500');
                break;
            case 401:
                message = i18n.t('auth.errors.401');
                break;
            default:
                message = error;
        }
    }
    yield put({type: COMMON_FAILED, error: message});
}

/**
 * get QuestionTypes
 */
function* getQuestionTypes() {
    const options = {
        body: JSON.stringify({  }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const questionTypes = yield call(fetchJSON, process.env.REACT_APP_BACKEND + '/common/questionTypes', options);
        yield put({type: QUESTION_TYPE_SUCCESS, questionTypes: questionTypes });
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = i18n.t('auth.errors.500');
                break;
            case 401:
                message = i18n.t('auth.errors.401');
                break;
            default:
                message = error;
        }
        yield put({type: QUESTION_TYPE_FAILED, error: message});
    }
}

/**
 * get Games
 */
function* getGames() {
    const options = {
        body: JSON.stringify({  }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const games = yield call(fetchJSON, process.env.REACT_APP_BACKEND + '/common/games', options);
        yield put({type: GAME_SUCCESS, games: games });
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = i18n.t('auth.errors.500');
                break;
            case 401:
                message = i18n.t('auth.errors.401');
                break;
            default:
                message = error;
        }
        yield put({type: GAME_FAILED, error: message});
    }
}

export function* watchCommonSaga(): any {
    yield takeEvery(COMMON_REQUEST, getCommon);
}

export function* watchQuestionTypeSaga(): any {
    yield takeEvery(QUESTION_TYPE_REQUEST, getQuestionTypes);
}

export function* watchGamesSaga(): any {
    yield takeEvery(GAME_REQUEST, getGames);
}

function* commonSaga(): any {
    yield all([
        fork(watchCommonSaga),
        fork(watchQuestionTypeSaga),
        fork(watchGamesSaga)
    ]);
}

export default commonSaga;
