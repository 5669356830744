import { isUserAuthenticated, getLoggedInUser } from './authUtils';

/**
 * Fetch data from given url
 * @param {*} url
 * @param {*} options
 */
const fetchJSON = (url, options = {}) => {

    if (isUserAuthenticated()) {
        const user = getLoggedInUser();
        options.headers['Authorization'] = 'Bearer ' + user.token;
    }

    return fetch(url, options)
        .then(response => {
            if (!response.status === 200) {
                throw response.json();
            }
            return response.json();
        })
        .then(json => {
            return json;
        })
        .catch(error => {
            throw error;
        });
};

export { fetchJSON };
