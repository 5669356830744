// @flow
import jwtDecode from 'jwt-decode';
import { Cookies } from 'react-cookie';

/**
 * Checks if user is authenticated
 */
const isUserAuthenticated = () => {
    const user = getLoggedInUser();
    if (!user) {
        return false;
    }
    const decoded = jwtDecode(user.token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
        console.warn('access token expired');
        return false;
    } else {
        return true;
    }
};

/**
 * Returns the logged in user
 */
const getLoggedInUser = () => {
    const cookies = new Cookies();
    const user = cookies.get('user');
    return user ? (typeof user == 'object' ? user : JSON.parse(user)) : null;
};

const getLiveSession = (name) => {
    const cookies = new Cookies();
    const cookie = cookies.get(name);
    return cookie ? (typeof cookie == 'object' ? cookie : JSON.parse(cookie)) : null;
};

function changeBodyBackgroundImage(value) {
    if (document.body) {
        document.body.classList.remove('authentication-bg');
        document.body.style.backgroundAttachment = 'fixed';
        document.body.style.backgroundPosition = 'center';
        document.body.style.backgroundSize = 'cover';
        document.body.style.backgroundImage = 'url(' + value + ')';
    }
    return true;
}

function changeBodyBackgroundColor(value) {
    if (document.body) {
        document.body.classList.remove('authentication-bg');
        document.body.style.backgroundColor = value;
    }
    return true;
}

export { isUserAuthenticated, getLoggedInUser, getLiveSession, changeBodyBackgroundColor, changeBodyBackgroundImage };
