import {take,put,call,fork} from 'redux-saga/effects'
import {eventChannel} from 'redux-saga'
import {
    LIVE_ESCAPE_GET, LIVE_ESCAPE_UPDATE
} from '../constants/escapeConstants';

import {onEscapeUpdated} from '../actions/escapeAction'
import {getSocket} from "../../../socket";

function connect() {
    const room = window.location.href.substr(window.location.href.lastIndexOf('/') + 1);
    const type = (room.indexOf('-') === -1) ? 'token' : 'id';
    const socket = getSocket(room);
    return new Promise(resolve => {
        console.log('LISTENING TO escape');
        socket.on('connect', () => {
            socket.emit('room', {room,type});
            resolve(socket);
        });
    });
}

function* liveGetEscape(socket) {
    const channel = yield call(subscribeEscape, socket);
    while (true) {
        let action = yield take(channel);
        yield put(action);
    }
}

function* liveUpdateEscape(socket) {

    while (true) {
        const {escape} = yield take(LIVE_ESCAPE_UPDATE);
        socket.emit('update-escape', escape.id);
    }
}

export function* subscribeEscape(socket) {
    yield 1;
    return new eventChannel(emit => {
        const update = escape => {
            return  emit(onEscapeUpdated(escape));
        }
        socket.on('get-escape', update);
        return () => {}
    })
}

export function* LiveEscapeSaga() {
    yield take(LIVE_ESCAPE_GET)
    const socket = yield call(connect)
    yield fork(liveGetEscape, socket)
    yield fork(liveUpdateEscape, socket)
}