// @flow
import {
    ESCAPE_ROOM_GET, ESCAPE_ROOM_GET_SUCCESS, ESCAPE_ROOM_GET_FAILED,
    ESCAPE_ROOM_REFRESH, ESCAPE_ROOM_REFRESH_SUCCESS, ESCAPE_ROOM_REFRESH_FAILED,
    ESCAPE_ROOM_UPDATE, ESCAPE_ROOM_UPDATE_SUCCESS, ESCAPE_ROOM_UPDATE_FAILED,
    ESCAPE_ROOM_LOGIN, ESCAPE_ROOM_LOGIN_SUCCESS, ESCAPE_ROOM_LOGIN_FAILED,
    ESCAPE_ROOM_LOGOUT, ESCAPE_UPDATE, ESCAPE_UPDATE_SUCCESS, ESCAPE_UPDATE_FAILED
} from './constants';

const initialState = {
    escapeRoom: '',
    escapeRoomData: {},
    loading: false,
    error: '',
    login: false,
    myDashboard: {}
};

export default function EscapeRoomReducer(state= initialState, action) {
    switch (action.type) {

        case ESCAPE_ROOM_GET:
            return { ...state, escapeRoom: action.escapeRoom, loading: true, error: null };
        case ESCAPE_ROOM_GET_SUCCESS:
            return { ...state, escapeRoom: action.escapeRoom, loading: false };
        case ESCAPE_ROOM_GET_FAILED:
            return { ...state, error: action.error, loading: false };

        case ESCAPE_ROOM_REFRESH:
            return { ...state, escapeRoomData: action.escapeRoomData, loading: true, error: null };
        case ESCAPE_ROOM_REFRESH_SUCCESS:
            return { ...state, escapeRoomData: action.escapeRoomData, loading: false };
        case ESCAPE_ROOM_REFRESH_FAILED:
            return { ...state, error: action.error, loading: false };

        case ESCAPE_ROOM_UPDATE:
            return { ...state, loading: true, error: null };
        case ESCAPE_ROOM_UPDATE_SUCCESS:
            return { ...state, escapeRoom: action.escapeRoom, loading: false };
        case ESCAPE_ROOM_UPDATE_FAILED:
            return { ...state, error: action.payload, loading: false };

        case ESCAPE_ROOM_LOGIN:
            return { ...state, login: action.login, loading: true, error: null };
        case ESCAPE_ROOM_LOGIN_SUCCESS:
            return { ...state, login: action.login, loading: false };
        case ESCAPE_ROOM_LOGIN_FAILED:
            return { ...state, error: action.error, loading: false };

        case ESCAPE_ROOM_LOGOUT:
            return { ...state, escapeRoom: null, escapeRoomData: null, login: false };

        case ESCAPE_UPDATE:
            return { ...state, loading: true, error: null };
        case ESCAPE_UPDATE_SUCCESS:
            return { ...state, escape: action.escape, loading: false };
        case ESCAPE_UPDATE_FAILED:
            return { ...state, error: action.payload, loading: false };

        default:
            return { ...state };
    }
}