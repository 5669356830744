import {
    LIVE_ESCAPE_GET,
    LIVE_ESCAPE_UPDATE,
    ON_LIVE_ESCAPE_UPDATED
} from '../constants/escapeConstants';

export const liveGetEscape = (id, type) => {
    return {
        type : LIVE_ESCAPE_GET,
        payload: {id, type},
    }
}

export const liveUpdateEscape = (id, status) => {
    return {
        type : LIVE_ESCAPE_UPDATE,
        payload: {id, status},
    }
}

export const onEscapeUpdated = payload => {
    return {
        type : ON_LIVE_ESCAPE_UPDATED,
        payload
    }
}

