// @flow
import {
    ESCAPE_ROOM_GET,
    ESCAPE_ROOM_UPDATE,
    ESCAPE_ROOM_REFRESH,
    ESCAPE_ROOM_LOGIN,
    ESCAPE_ROOM_LOGOUT,
    ESCAPE_UPDATE
} from './constants';

type EscapeRoomAction = { type: string, payload: {} | string };

export const escapeRoomGetSaga = (id): EscapeRoomAction => ({
    type: ESCAPE_ROOM_GET,
    payload: {id},
});

export const escapeRoomRefreshSaga = (id, refreshEscapeRoom: any): EscapeRoomAction => ({
    type: ESCAPE_ROOM_REFRESH,
    payload: {id, refreshEscapeRoom},
});

export const escapeRoomUpdateSaga = (path, values, id, history, forwarding): EscapeRoomAction => ({
    type: ESCAPE_ROOM_UPDATE,
    payload: {path, values, id, history, forwarding},
});

export const escapeRoomLoginSaga = (values, initDashboard: any): EscapeRoomAction => ({
    type: ESCAPE_ROOM_LOGIN,
    payload: {values, initDashboard},
});

export const escapeRoomLogoutSaga = (id, escape, token, history: any): EscapeRoomAction => ({
    type: ESCAPE_ROOM_LOGOUT,
    payload: {id, escape, token, history},
});

export const escapeUpdateSaga = (id, status, pausedSeconds): EscapeRoomAction => ({
    type: ESCAPE_UPDATE,
    payload: {id, status, pausedSeconds},
});