import {
    LIVE_ESCAPE_ADMIN_GET,
    LIVE_ESCAPE_ADMIN_UPDATE,
    ON_LIVE_ESCAPE_ADMIN_UPDATED
} from "../constants/escapeAdminConstants";

export const liveGetEscapeAdmin = (id) => {
    return {
        type : LIVE_ESCAPE_ADMIN_GET,
        payload: {id},
    }
}

export const liveUpdateEscapeAdmin = (id, status) => {
    return {
        type : LIVE_ESCAPE_ADMIN_UPDATE,
        payload: {id, status},
    }
}

export const onEscapeAdminUpdated = payload => {
    return {
        type : ON_LIVE_ESCAPE_ADMIN_UPDATED,
        payload
    }
}

