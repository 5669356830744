/* COMMON */
export const COMMON_REQUEST = 'COMMON_REQUEST';
export const COMMON_SUCCESS = 'COMMON_SUCCESS';
export const COMMON_FAILED = 'COMMON_FAILED';
export const QUESTION_TYPE_REQUEST = 'QUESTION_TYPE_REQUEST';
export const QUESTION_TYPE_SUCCESS = 'QUESTION_TYPE_SUCCESS';
export const QUESTION_TYPE_FAILED = 'QUESTION_TYPE_FAILED';
export const GAME_REQUEST = 'GAME_REQUEST';
export const GAME_SUCCESS = 'GAME_SUCCESS';
export const GAME_FAILED = 'GAME_FAILED';




