import {take,put,call,fork} from 'redux-saga/effects'
import {eventChannel} from 'redux-saga'
import {
    LIVE_ESCAPE_ROOM_GET, LIVE_ESCAPE_ROOM_UPDATE
} from '../constants/escapeRoomConstants';

import {getSocket} from "../../../socket";
import {onEscapeRoomUpdated} from "../actions/escapeRoomAction";

function connect() {
    const room = window.location.href.substr(window.location.href.lastIndexOf('/') + 1);
    const socket = getSocket(room);
    return new Promise(resolve => {
        socket.on('connect', () => {
            socket.emit('escapeRoom', room);
            resolve(socket);
        });
    });
}

function* liveGetEscapeRoom(socket) {
    const channel = yield call(subscribeEscapeRoom, socket);
    while (true) {
        let action = yield take(channel);
        yield put(action);
    }
}

function* liveUpdateEscapeRoom(socket) {

    while (true) {
        const {escapeRoom} = yield take(LIVE_ESCAPE_ROOM_UPDATE);
        socket.emit('update-escapeRoom', escapeRoom.id);
    }
}

export function* subscribeEscapeRoom(socket) {
    yield 1;
    return new eventChannel(emit => {
        const update = escapeRoom => {
            return  emit(onEscapeRoomUpdated(escapeRoom));
        }
        socket.on('get-escapeRoom', update);
        return () => {}
    });
}

export function* LiveEscapeRoomSaga() {
    yield take(LIVE_ESCAPE_ROOM_GET)
    const socket = yield call(connect)
    yield fork(liveGetEscapeRoom, socket)
    yield fork(liveUpdateEscapeRoom, socket)
}