import {ON_LIVE_ESCAPE_ADMIN_UPDATED} from "../constants/escapeAdminConstants";

const initialState = {
    escape : [],
    escapeRooms : []
}

export default function LiveEscapeAdminReducer(state = initialState, action) {
    switch (action.type) {
        case ON_LIVE_ESCAPE_ADMIN_UPDATED:
            return {
                ...state,
                escapeRooms : action.payload.escapeRooms,
                escape : action.payload.escape
            }
        default:
            return state
    }
}