import {
    LIVE_NEXT_QUESTION,
    LIVE_QUESTION_GET,
    LIVE_QUESTION_GET_FAILED,
    LIVE_QUESTION_GET_SUCCESS, LIVE_QUESTION_ON_CHANGE, LIVE_QUESTION_RESET,
    LIVE_QUESTION_UPDATE, LIVE_QUESTION_UPDATE_FAILED, LIVE_QUESTION_UPDATE_SUCCESS
} from "../constants/questionConstants";

const initialState = {
    liveQuestion : [],
    error : '',
    success : ''
}

export default function LiveQuestionReducer(state = initialState, action) {
    switch (action.type) {

        case LIVE_QUESTION_GET:
            return { ...state, liveQuestion: action.liveQuestion, loading: true, error: null };
        case LIVE_QUESTION_GET_SUCCESS:
            return { ...state, liveQuestion: action.liveQuestion, loading: false };
        case LIVE_QUESTION_GET_FAILED:
            return { ...state, error: action.payload, loading: false };

        case LIVE_QUESTION_UPDATE:
            return { ...state, loading: false, error: false, success: false };
        case LIVE_QUESTION_UPDATE_SUCCESS:
            return { ...state, liveQuestion: action.liveQuestion, success: action.id, error: false, loading: false };
        case LIVE_QUESTION_UPDATE_FAILED:
            return { ...state, error: action.payload, success: false, loading: false };

        case LIVE_QUESTION_RESET:
            return { ...state, error: false, success: false, loading: false };

        case LIVE_NEXT_QUESTION:
            return { ...state, error: false, success: false, loading: false };

        case LIVE_QUESTION_ON_CHANGE:
            return { ...state, liveQuestion: action.liveQuestion, success: action.id, error: false, loading: false };

        default:
            return state
    }
}